import React from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import styles from "./FOOTER.module.scss";

export default function FOOTER(props) {
  const { t } = useTranslation();
  return (
    <div className={cn(styles.root, "footer-new")}>
      <div className={styles.flexCol}>
        {/* <div className={styles.flexCol__cell}> */}
          <div className={styles.flexRow}>
            <div className={styles.flexRow__cell}>
              <img
                src={require("assets/39f27266f1850bd6d0f0f8512829e119.png")}
                alt="alt text"
                className={styles.image}
              />
            </div>
            <div className={styles.flexRow__spacer} />
            {/* <div className={styles.flexRow__cell1}> */}
            <h1 className={`${styles.title} ${styles.flexRow__cell1}`}>
              {t("footer.collaborate")}
            </h1>
            {/* </div> */}
          </div>
        {/* </div> */}

        <div className={styles.flexRow1}>
          <div className={styles.flexRow1__cell}>
            <div className={styles.flexCol1}>
              <h5 className={styles.highlights}>{t("footer.hear")}</h5>
              <px-grid
                x="0% 79.55% 20.45%"
                y="26px 46fr 0px"
                md-x="0% 33.33% 66.67%"
                md-y="18px minmax(40px,40px) 0px"
                sm-x="0% 46.25% 53.75%"
                sm-y="18px minmax(40px,40px) 0px"
                xs-x="0% 52.86% 47.14%"
                xs-y="18px minmax(40px,40px) 0px"
                xxs-x="0% 57.36% 42.64%"
                xxs-y="18px minmax(40px,40px) 0px"
                tn-x="0% 64.91% 35.09%"
                tn-y="18px minmax(35px,35px) 0px"
              >
                <div
                  className={`${styles.content_box} ${styles.highlights1}`}
                  onClick={() => window.open("/Contact", "_self")}
                >
                  {/* <h5
                    onClick={() => window.open("/Contact", "_self")}
                    className={styles.highlights1}
                  > */}
                  {t("footer.together")}
                  {/* </h5> */}
                </div>
              </px-grid>
            </div>
          </div>
          <div className={styles.flexRow1__spacer} />
          {/* <div className={styles.flexRow1__cell1}> */}
          <div className={`${styles.flexRow2} ${styles.flexRow1__cell1}`}>
            {/* <div className={styles.flexRow2__cell}> */}
            <div className={styles.flexCol2}>
              <h5 className={styles.highlights2}>{t("footer.who")}</h5>
              <div
                onClick={() => window.open("/About", "_self")}
                className={styles.text}
              >
                {t("footer.about")}
              </div>
              {/* <div className={styles.flexCol2__cell}> */}
              <div
                onClick={() => window.open("/Work", "_self")}
                className={styles.text1}
              >
                {t("footer.works")}
              </div>
              {/* </div> */}
              <div
                onClick={() => window.open("/Privacypolicy", "_self")}
                className={styles.text3}
              >
                {t("footer.policy")}
              </div>
              <div
                  className={styles.text3}
                  onClick={() =>
                      window.open(
                          "https://server.milessun.org",
                          "_blank"
                      )
                  }
              >
                {t("footer.staff_portal")}
              </div>
            </div>
            {/* </div> */}
            <div className={styles.flexRow2__spacer} />
            {/* <div className={styles.flexRow2__cell1}> */}
            <div className={`${styles.flexCol3} ${styles.flexRow2__cell1}`}>
              <h5 className={styles.highlights3}>{t("footer.social")}</h5>
              <div
                className={styles.text5}
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/company/ms-investment-group/",
                    "_self"
                  )
                }
              >
                {t("footer.linkedin")}
              </div>
              <div className={styles.text6}>{/* {t("footer.facebook")} */}</div>
            </div>
            {/* </div> */}
            <div className={styles.flexRow2__spacer1} />
            {/* <div className={styles.flexRow2__cell2}> */}
            <div className={`${styles.flexCol4} ${styles.flexRow2__cell2}`}>
              <div className={styles.text21}>{t("footer.contact")}</div>

              <div className={styles.flexRow3}>
                <div className={styles.flexRow3__cell}>
                  <img
                    src={require("assets/c02dc96cdf0740bd1dfd8bd087e6f855.png")}
                    alt="alt text"
                    className={styles.image1}
                  />
                </div>
                {/* <div className={styles.flexRow3__cell1}> */}
                <div className={styles.text7}>info@milessun.org</div>
                {/* </div> */}
              </div>

              <div className={styles.flexRow4}>
                <div className={styles.flexRow4__cell}>
                  <img
                    src={require("assets/2a90ecf794eaa83e24c340986f04387a.png")}
                    alt="alt text"
                    className={styles.icon}
                  />
                </div>
                {/* <div className={styles.flexRow4__cell1}> */}
                <div className={styles.text8}>+852 5801 1135</div>
                {/* </div> */}
              </div>
            </div>
            {/* </div> */}
          </div>
          {/* </div> */}
        </div>

        <hr size={1} className={styles.line} />
        <div className={styles.text11}>
          © 1997-2023 Miles Sun. All rights reserved.{" "}
        </div>
      </div>
    </div>
  );
}

FOOTER.inStorybook = true;
